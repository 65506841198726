import React from 'react'

import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Theme from '../components/Theme/theme.js'

import '../components/Window/window.css'
import './about.css'

import AboutPortraitPoster from '../assets/jeffwolff-poster-2020.jpg';
import AboutPortraitWebM from '../assets/jeffwolff-moshed-2020.webm';
import AboutPortraitMp4 from '../assets/jeffwolff-moshed-2020.mp4';
import EqualizerGif from '../assets/eq.gif';

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      songTitle: null,
      songArtist: null,
      songURL: null
    }
  }

  componentDidMount() {
    fetch("https://ws.audioscrobbler.com/2.0/?method=user.getrecenttracks&user=jeffwolff&api_key=205e52324c3a9f7e346a7c34dca5e8d8&format=json")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            songTitle: result.recenttracks.track[0].name,
            songArtist: result.recenttracks.track[0].artist['#text'],
            songURL: result.recenttracks.track[0].url,
          });
        },
        (error) => {
          console.log('Last.fm api error');
        }
      )
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
    const pageTitle = 'About'
    const pageDescription = 'Crafting pixels since 2007.'

    const { songTitle, songArtist } = this.state;
    const lastFmProfileUrl = "https://www.last.fm/user/jeffwolff";

    return (
      <Layout location={this.props.location}>
        <Theme />
        <Helmet
          title={`${pageTitle} - ${siteTitle}`}>
          <meta name="description" content={pageDescription} />
          <meta property="og:url" content={`${siteUrl}${this.props.location.pathname}`} />
          <meta property="og:title" content={`${pageTitle} - ${siteTitle}`} />
          <meta property="og:description" content={pageDescription} />
          {/* <meta property="og:image" content={`${siteUrl}${AboutPortraitPoster}`} />*/}
          <meta name="google" content="notranslate" />
        </Helmet>
        <div className="about-me container">
          <div className="content">
            {/* <figure className="profile">
              <video loop muted autoPlay playsInline poster={AboutPortraitPoster}>
                <source src={ AboutPortraitWebM } type="video/webm" />
                <source src={ AboutPortraitMp4 } type="video/mp4" />
              </video>
              <figcaption>
                Crafting pixels since 2007
              </figcaption>
            </figure> */}
            <div className="Rte info">
              <h1 className="title h2">About Me</h1>
              <p>Front-end web developer and designer with over 15 years experience who strives to create elegant, engaging, and easy-to-use websites.</p>
              <p>Last song played: <a href={lastFmProfileUrl} target="_blank">{songArtist} - {songTitle}</a> <img src={EqualizerGif} style={{ width: '10px' }} /> <br /><a href={lastFmProfileUrl} target="_blank"><small>powered by last.fm api</small></a></p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default About
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        tagline
        siteUrl
      }
    }
  }
`
